"use client";
import React, { useState } from "react";
import Link from "next/link";

import useBodyFreeze from "hooks/useBodyFreeze";

import { Block, Flex } from "components/Layout";
import Button from "components/Button";
import Logo from "components/Logo";

import styles from "./Header.module.scss";

const MobileMenu = ({ isAuth }: { isAuth: boolean }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const navListItemMobile = `${styles.navListItemMobile} body-text`;

  const CloseButton = () => {
    return (
      <button
        className={styles.menuButton}
        type="button"
        onClick={() => {
          setIsMobileMenuOpen(!isMobileMenuOpen);
        }}
      >
        <svg
          width="27"
          height="27"
          viewBox="0 0 27 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M24.8027 2L2.00007 24.8027" stroke="#FFFCF4" strokeWidth="4" />
          <path d="M24.8027 25L2.00008 2.19734" stroke="#FFFCF4" strokeWidth="4" />
        </svg>
      </button>
    );
  };

  const OpenButton = () => {
    return (
      <button
        type="button"
        onClick={() => {
          setIsMobileMenuOpen(!isMobileMenuOpen);
        }}
      >
        <span className={styles.menuButtonText}>Meny</span>
        <svg
          width="31"
          height="22"
          viewBox="0 0 31 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0.332031" y="18.333" width="30.668" height="3.66667" fill="#373737" />
          <rect x="0.332031" y="9.16699" width="30.668" height="3.66667" fill="#373737" />
          <rect x="0.332031" width="30.668" height="3.66667" fill="#373737" />
        </svg>
      </button>
    );
  };

  const OpenMenu = () => {
    useBodyFreeze();
    return (
      <>
        <div className={styles.headerMobile}>
          <CloseButton />
          <nav className={styles.mobileFlex}>
            <div className={styles.logoWrapMobile}>
              <Link
                title="Til forsiden"
                role="link"
                onClick={() => {
                  setIsMobileMenuOpen(!isMobileMenuOpen);
                }}
                href="/"
              >
                <Logo color="#fffcf4" />
              </Link>
            </div>
            <div data-animate-down data-animation-order="2">
              <Link
                className={navListItemMobile}
                onClick={() => {
                  setIsMobileMenuOpen(!isMobileMenuOpen);
                }}
                href="/sykler"
                data-testid="our-bikes"
              >
                Sykkelmodeller
              </Link>
            </div>

            {/* <div data-animate-down data-animation-order="2">
                <Link
                  className={navListItemMobile}
                  onClick={() => {
                    setIsMobileMenuOpen(!isMobileMenuOpen);
                  }}
                  href="/pris"
                >
                  Priser
                </Link>
              </div> */}

            <div>
              <div data-animate-down data-animation-order="2">
                <Link
                  className={navListItemMobile}
                  onClick={() => {
                    setIsMobileMenuOpen(!isMobileMenuOpen);
                  }}
                  href="/whee-sykkel-abonnement"
                >
                  Om tjenesten
                </Link>
              </div>

              <div data-animate-down data-animation-order="2">
                <Link
                  className={navListItemMobile}
                  onClick={() => {
                    setIsMobileMenuOpen(!isMobileMenuOpen);
                  }}
                  href="/whee-for-bedrifter"
                >
                  Whee! bedrift
                </Link>
              </div>

              <div data-animate-down data-animation-order="2">
                <a
                  href={"https://www.wheestore.no?utm_source=whee&utm_medium=referral"}
                  target="_blank"
                  rel="noreferrer"
                  className="body-text"
                  onClick={() => {
                    setIsMobileMenuOpen(!isMobileMenuOpen);
                  }}
                >
                  Nettbutikk
                </a>
              </div>

              <div data-animate-down data-animation-order="4">
                <Link
                  className={navListItemMobile}
                  onClick={() => {
                    setIsMobileMenuOpen(!isMobileMenuOpen);
                  }}
                  href={isAuth ? "/min-side" : "/login?referer=min-side"}
                >
                  {isAuth ? "Min side" : "Kunde? Logg inn"}
                </Link>
              </div>

              <Block top={3}>
                <div data-animate-down data-animation-order="1">
                  <Button color="red" type="button" link="/sykkelabonnement" menu>
                    <span
                      className="text-button"
                      onClick={() => {
                        setIsMobileMenuOpen(!isMobileMenuOpen);
                      }}
                    >
                      Bestill Whee!-sykkel
                    </span>
                  </Button>
                </div>
              </Block>
            </div>

            <div>
              <p data-animate-down data-animation-order="8">
                <a href="mailto:hei@whee.no" className={`${navListItemMobile} link-underline`}>
                  hei@whee.no
                </a>
              </p>
              <p data-animate-down data-animation-order="9">
                <span className={navListItemMobile}>Verksted: </span>
                <a className={`${navListItemMobile} link-underline`} href="tel:94467068">
                  944 67 068
                </a>
              </p>

              <Block>
                <div data-animate-down data-animation-order="7">
                  <Link
                    className={navListItemMobile}
                    onClick={() => {
                      setIsMobileMenuOpen(!isMobileMenuOpen);
                    }}
                    href="/personvern-og-cookies"
                  >
                    Personvern og cookies
                  </Link>
                </div>
              </Block>

              <Block top={5} data-animate-in data-animation-order="9">
                <div className={styles.some}>
                  <a
                    href="https://www.instagram.com/wheebikeoslo/"
                    title="Whee! på Instagram"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/images/instagram.svg" alt="Instagram ikon" />
                  </a>
                  <a
                    href="https://www.facebook.com/wheebike/"
                    title="Whee! på Facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/images/facebook.svg" alt="Facebook ikon" />
                  </a>
                </div>
              </Block>
            </div>
          </nav>
        </div>
      </>
    );
  };

  return (
    <>
      <header className={styles.headerMobileWrap}>
        {/* Show normal meny header */}
        {!isMobileMenuOpen && (
          <Flex justify="spaceBetween">
            <div className={styles.logoWrapMobileBar}>
              <Link href="/">
                <Logo color="#F05339" />
              </Link>
            </div>
            <OpenButton />
          </Flex>
        )}

        {/* Show open mobile menu */}
        {isMobileMenuOpen && <OpenMenu />}
      </header>
      <div className={styles.headerShadowMobile} />
      <div className={styles.headerBoxMobile} />
    </>
  );
};

export default MobileMenu;
