function parseCookies(cookie: string) {
  let list: any = {};
  if (!cookie) return list;

  cookie.split(`;`).map(function (cookie) {
    let [name, ...rest] = cookie.split(`=`);

    name = name?.trim();
    if (!name) return;
    const value = rest.join(`=`).trim();

    if (!value) return;
    list[name] = decodeURIComponent(value);
  });

  return list;
}

export default parseCookies;
