"use client";
import React, { useState } from "react";
import Link from "next/link";
import { useRouter } from "next/navigation";

import useBodyFreeze from "hooks/useBodyFreeze";

import { Block, Flex } from "components/Layout";
import Logo from "components/Logo";

import { SERVICE_BOOKING_URL } from "/constants";
import styles from "./Header.module.scss";
import postData from "/utils/postData";

const MobileMenu = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const router = useRouter();
  const navListItemMobile = `${styles.navListItemMobile} body-text`;

  const onLogout = () => {
    return postData("/api/auth/logout", {});
  };

  const CloseButton = () => {
    return (
      <button
        className={styles.menuButton}
        type="button"
        onClick={() => {
          setIsMobileMenuOpen(!isMobileMenuOpen);
          window?.HubSpotConversations?.widget?.load();
        }}
      >
        <svg
          width="27"
          height="27"
          viewBox="0 0 27 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M24.8027 2L2.00007 24.8027" stroke="#FFFCF4" strokeWidth="4" />
          <path d="M24.8027 25L2.00008 2.19734" stroke="#FFFCF4" strokeWidth="4" />
        </svg>
      </button>
    );
  };

  const OpenButton = () => {
    return (
      <button
        type="button"
        onClick={() => {
          setIsMobileMenuOpen(!isMobileMenuOpen);
          window?.HubSpotConversations?.widget?.remove();
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Block bottom={2}>
            <p className="color--black-40 font-family-medium">Meny</p>
          </Block>
          <svg
            width="31"
            height="22"
            viewBox="0 0 31 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="0.332031" y="18.333" width="30.668" height="3.66667" fill="#373737" />
            <rect x="0.332031" y="9.16699" width="30.668" height="3.66667" fill="#373737" />
            <rect x="0.332031" width="30.668" height="3.66667" fill="#373737" />
          </svg>
        </div>
      </button>
    );
  };

  const OpenMenu = () => {
    useBodyFreeze();
    return (
      <>
        <div className={styles.headerMobile}>
          <nav className={styles.mobileFlexAuth}>
            <div>
              <div className={styles.logoWrapMobileAuth}>
                <Link
                  title="Til forsiden"
                  role="link"
                  onClick={() => {
                    setIsMobileMenuOpen(!isMobileMenuOpen);
                  }}
                  href="/"
                >
                  <svg
                    className={styles.arrowBack}
                    width="21"
                    height="18"
                    viewBox="0 0 21 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18.7721 9.22499L19.7701 9.16259L19.6453 7.16649L18.6473 7.22889L4.67455 8.10246L9.06584 1.41542L9.61475 0.579541L7.11962 0.735488L1.90496 8.6764L1.49328 9.30331L1.97984 9.87406L8.16535 17.1298L10.6381 16.9476L9.98934 16.1866L4.79934 10.0986L18.7721 9.22499Z"
                      fill="#FFFCF4"
                      stroke="#FFFCF4"
                      strokeLinecap="square"
                    />
                  </svg>
                  <span>tilbake til whee.no</span>
                </Link>
                <CloseButton />
              </div>
              <div className={`${styles.linksContainer}`}>
                <div data-animate-down data-animation-order="2">
                  <Link
                    className={navListItemMobile}
                    onClick={() => {
                      setIsMobileMenuOpen(!isMobileMenuOpen);
                    }}
                    href="/min-side"
                  >
                    Min Whee!
                  </Link>
                </div>
                <div data-animate-down data-animation-order="2">
                  <Link
                    className={navListItemMobile}
                    onClick={() => {
                      setIsMobileMenuOpen(!isMobileMenuOpen);
                    }}
                    href={SERVICE_BOOKING_URL}
                  >
                    Sykkelhjelp
                  </Link>
                </div>
                <div data-animate-down data-animation-order="2">
                  <Link
                    className={navListItemMobile}
                    onClick={() => {
                      setIsMobileMenuOpen(!isMobileMenuOpen);
                    }}
                    href="/min-side/abonnementer"
                  >
                    Sykkel og abonnement
                  </Link>
                </div>

                <div data-animate-down data-animation-order="5">
                  <a className={navListItemMobile} href="/min-side/profil" rel="noreferrer">
                    Kundeinfo
                  </a>
                </div>

                <div data-animate-down data-animation-order="6">
                  <Link
                    className={navListItemMobile}
                    onClick={() => {
                      setIsMobileMenuOpen(!isMobileMenuOpen);
                    }}
                    href="/min-side/betalinger"
                  >
                    Betaling
                  </Link>
                </div>

                <div data-animate-down data-animation-order="6">
                  <Link
                    className={navListItemMobile}
                    onClick={() => {
                      setIsMobileMenuOpen(!isMobileMenuOpen);
                    }}
                    href="/min-side/kundeavtale"
                  >
                    Regler og betingelser
                  </Link>
                </div>

                <div data-animate-down data-animation-order="6">
                  <Link
                    className={navListItemMobile}
                    onClick={async () => {
                      await onLogout();
                      setIsMobileMenuOpen(!isMobileMenuOpen);
                    }}
                    href="/"
                  >
                    Logg ut
                  </Link>
                </div>
              </div>
            </div>
            <div className={styles.containerLogoMobileNavigation}>
              <div className={styles.logoMobileNavigation}>
                <Logo color="#fff" />
              </div>
              <p className="body-text">Nordlandsgata 5, 0483 Oslo</p>
            </div>
          </nav>
        </div>
      </>
    );
  };

  return (
    <>
      <header className={styles.headerMobileWrap}>
        {/* Show normal meny header */}
        {!isMobileMenuOpen && (
          <Flex justify="spaceBetween">
            {router.pathname !== "/min-side" && (
              <svg
                onClick={() => router.back()}
                style={{ marginRight: 12 }}
                width="16"
                height="22"
                viewBox="0 0 16 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.8462 2L2 13L13.8462 24"
                  stroke="#373737"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
            <div className={styles.logoWrapMobileBar}>
              <Link href="/">
                <Logo height={26} color="#F05339" />
              </Link>
            </div>
            <OpenButton />
          </Flex>
        )}

        {/* Show open mobile menu */}
        {isMobileMenuOpen && <OpenMenu />}
      </header>
      <div className={styles.headerShadowMobile} />
      <div className={styles.headerBoxMobile} />
    </>
  );
};

export default MobileMenu;
