"use client";
import React from "react";
import Link from "next/link";

import { Flex } from "components/Layout";
import Logo from "components/Logo";

import styles from "./Header.module.scss";

const MobileMenuB2BPortal = () => {
  return (
    <>
      <header className={styles.headerMobileWrap}>
        <Flex justify="spaceBetween">
          <div className={styles.logoWrapMobileBar}>
            <Link href="/">
              <Logo color="#F05339" />
            </Link>
          </div>
        </Flex>
      </header>
      <div className={styles.headerShadowMobile} />
      <div className={styles.headerBoxMobile} />
    </>
  );
};

export default MobileMenuB2BPortal;
