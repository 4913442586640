type Json = { [key: string]: any };

async function postData(url: string, data: Json) {
  const response = await fetch(url, {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });

  if (response.redirected) {
    window.location.href = response.url; // Redirect the user to the redirected URL
    return;
  }

  if (!response.ok) {
    let errorMessage = "Beklager noe gikk galt. Venligst prøv igjen";
    const error = await response.json();
    if (error.message) {
      errorMessage = error.message;
    }
    throw new Error(errorMessage);
  }

  return response.json();
}

export default postData;
