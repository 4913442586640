const Logo = ({ color = "#373737" }: { color: string }) => {
  return (
    <svg
      width="240"
      height="62"
      viewBox="0 0 240 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M74.0917 8.30223L62.2052 61.3092H49.364L37.0458 22.3966L24.7252 61.3092H11.8865L0 8.30223H11.5387L18.5661 48.4244L31.2321 8.30223H42.8572L55.5231 48.4244L62.5505 8.30223H74.0917ZM120.654 33.3786V61.3092H109.894V36.9237C109.894 29.3147 106.51 25.9417 100.785 25.9417C95.4056 25.9417 91.0693 29.5729 91.0693 36.9237V61.3092H80.2237V0.779333H91.0693V22.5687C94.1057 18.5909 98.7034 16.602 104.083 16.602C113.54 16.602 120.654 23.348 120.654 33.3786ZM170.975 42.111H138.786C139.306 50.0666 145.12 53.093 149.977 53.093C155.877 53.093 159.172 50.7574 161.774 46.9542L168.888 52.1416C165.938 56.6381 160.127 62 149.974 62C136.006 62 127.59 52.3161 127.59 39.3453C127.59 26.0278 137.394 16.6905 150.147 16.6905C162.813 16.6905 171.143 25.6836 171.143 39.6919C171.147 40.7294 171.061 41.5062 170.975 42.111ZM138.961 35.7116H160.129C159.609 28.4493 154.925 25.6811 150.152 25.6811C144.686 25.6811 139.829 28.882 138.961 35.7116ZM220.6 42.111H188.411C188.932 50.0666 194.745 53.093 199.602 53.093C205.502 53.093 208.798 50.7574 211.4 46.9542L218.514 52.1416C215.563 56.6381 209.752 62 199.6 62C185.631 62 177.215 52.3161 177.215 39.3453C177.215 26.0278 187.02 16.6905 199.772 16.6905C212.438 16.6905 220.768 25.6836 220.768 39.6919C220.773 40.7294 220.687 41.5062 220.6 42.111ZM188.586 35.7116H209.755C209.234 28.4493 204.55 25.6811 199.777 25.6811C194.311 25.6811 189.455 28.882 188.586 35.7116ZM232.139 0C236.737 0 240.814 3.45906 239.859 10.1166L235.089 42.5437C234.828 42.4577 233.18 42.3716 231.966 42.3716C230.839 42.3716 229.016 42.4577 228.669 42.5437L224.33 10.1166C223.548 4.06384 227.628 0 232.139 0ZM239.078 55.0819C239.078 58.8876 235.869 61.914 232.05 61.914C228.232 61.914 225.109 58.8876 225.109 55.0819C225.109 51.1901 228.232 48.0777 232.05 48.0777C235.869 48.0777 239.078 51.1901 239.078 55.0819Z"
        fill={color}
      />
    </svg>
  );
};

export default Logo;
