"use client";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import classNames from "classnames";
import { usePathname } from "next/navigation";

import { Section } from "/components/Layout";
import Logo from "/components/Logo";
import MobileMenuAuthUser from "/components/Header/MobileMenuAuthUser";
import MobileMenu from "/components/Header/MobileMenu";
import MobileMenuB2BPortal from "/components/Header/MobileMenuB2BPortal";

import parseCookies from "/utils/parseCookies";
import { SERVICE_BOOKING_URL } from "/constants";
import styles from "./Header.module.scss";

function NavLink({ title, href }: { title: string; href: string }) {
  const [isSelected, setIsSelected] = useState(false);
  const pathname = usePathname();

  useEffect(() => {
    setIsSelected(pathname === href);
  }, [pathname, href]);

  const LinkClass = classNames(styles.navListItem, {
    [styles["navListItem-selected"]]: isSelected
  });

  return (
    <Link href={href}>
      <p className={`${LinkClass} h5`}>{title}</p>
    </Link>
  );
}

const DesktopMenu = ({ isAuth }: { isAuth: boolean }) => {
  return (
    <>
      <header className={styles.header}>
        <Section outer="xsmall" innerNoLimit>
          <div className="flex justify-end w-full pt-1 items-center gap-4">
            <span>
              <svg
                width="37"
                height="8"
                viewBox="0 0 37 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M36.3536 4.35355C36.5488 4.15829 36.5488 3.84171 36.3536 3.64645L33.1716 0.464466C32.9763 0.269204 32.6597 0.269204 32.4645 0.464466C32.2692 0.659728 32.2692 0.976311 32.4645 1.17157L35.2929 4L32.4645 6.82843C32.2692 7.02369 32.2692 7.34027 32.4645 7.53553C32.6597 7.7308 32.9763 7.7308 33.1716 7.53553L36.3536 4.35355ZM0 4.5H36V3.5H0V4.5Z"
                  fill="#F45338"
                />
              </svg>
            </span>

            <Link
              className={styles.underline}
              href={isAuth ? "/min-side" : "/login?referer=min-side"}
            >
              {isAuth ? "Min side" : "Kunde? Logg inn"}
            </Link>
          </div>
          <div className="flex justify-between h-full pt-6">
            <div className="max-w-[150px] self-center h-full">
              <Link title="Til forsiden" href="/">
                <Logo color="#F05339" />
              </Link>
            </div>
            <nav className="flex flex-row justify-center h-full gap-8 self-center">
              <NavLink href="/sykler" title="Sykkelmodeller" />
              {/* <NavLink href="/pris" title="Priser" /> */}
              <NavLink href="/whee-sykkel-abonnement" title="Om tjenesten" />
              <NavLink href="/whee-for-bedrifter" title="Whee! bedrift" />
              <a
                href={"https://www.wheestore.no?utm_source=whee&utm_medium=referral"}
                target="_blank"
                rel="noreferrer"
                className="hover:underline-offset-8 hover:decoration-2 hover:decoration-green"
              >
                <h5>Nettbutikk</h5>
              </a>
            </nav>
          </div>
        </Section>
      </header>
    </>
  );
};

const DesktopMenuAuthUser = () => {
  return (
    <>
      <header className={styles.header}>
        <Section outer="xsmall" innerNoLimit>
          <div className="flex justify-start w-full pt-2 items-center gap-4">
            <span className={styles.arrowBack}>
              <svg
                width="37"
                height="8"
                viewBox="0 0 37 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM37 3.5L1 3.5V4.5L37 4.5V3.5Z"
                  fill="#F45338"
                />
              </svg>
            </span>

            <Link className={styles.underline} href="/">
              Tilbake til whee.no
            </Link>
          </div>
          <div className="flex justify-between h-full pt-6">
            <div className="max-w-[150px] self-center h-full">
              <Link title="Til forsiden" href="/">
                <Logo color="#F05339" />
              </Link>
            </div>
            <nav className="flex flex-row justify-center h-full gap-8 self-center">
              <NavLink href="/min-side" title="Min Whee!" />
              <NavLink href={SERVICE_BOOKING_URL} title="Sykkelhjelp" />
              <NavLink href="/min-side/abonnementer" title="Sykkel og abonnement" />
              <NavLink href="/min-side/profil" title="Kundeinfo" />
              <NavLink href="/min-side/betalinger" title="Betaling" />
              <NavLink href="/min-side/kundeavtale" title="Regler og betingelser" />
            </nav>
          </div>
        </Section>
      </header>
      <div className={styles.headerShadow} />
      <div className={styles.headerBox} />
    </>
  );
};

const DesktopMenuB2BPortal = () => {
  return (
    <>
      <header className={styles.header}>
        <Section outer="xsmall" innerNoLimit>
          <div className="flex justify-start w-full pt-2 items-center gap-4">
            <span className={styles.arrowBack}>
              <svg
                width="37"
                height="8"
                viewBox="0 0 37 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM37 3.5L1 3.5V4.5L37 4.5V3.5Z"
                  fill="#F45338"
                />
              </svg>
            </span>

            <Link className={styles.underline} href="/">
              Tilbake til whee.no
            </Link>
          </div>
          <div className="flex justify-between h-full pt-6">
            <div className="max-w-[150px] self-center h-full">
              <Link title="Til forsiden" href="/">
                <Logo color="#F05339" />
              </Link>
            </div>
          </div>
        </Section>
      </header>
      <div className={styles.headerShadow} />
      <div className={styles.headerBox} />
    </>
  );
};

const Header = () => {
  const [isAuth, setIsAuth] = useState(false);
  const pathname = usePathname();

  useEffect(() => {
    const isAuth = parseCookies(document.cookie)?.isAuth;

    if (isAuth) {
      setIsAuth(true);
    }
  }, [pathname]);

  const showAuthUserNavigationMenu = () => {
    // Customer has to be authenticated to see these pages
    // Show auth user navigation menu on these pages
    const arr = ["/min-side", "/service/bestill"];
    return arr.some((v) => {
      return pathname?.includes(v);
    });
  };

  // Render different header for B2B portal
  if (pathname?.includes("/min-bedrift")) {
    return (
      <>
        <DesktopMenuB2BPortal />
        <MobileMenuB2BPortal />
      </>
    );
  }

  // Render different header for /min-side authenticated users
  return (
    <>
      {showAuthUserNavigationMenu() ? <DesktopMenuAuthUser /> : <DesktopMenu isAuth={isAuth} />}
      {showAuthUserNavigationMenu() ? <MobileMenuAuthUser /> : <MobileMenu isAuth={isAuth} />}
    </>
  );
};

export default Header;
